#add-course {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 60px - 15vh);
    overflow: hidden;
}

.unit-info {
    display: grid;
    grid-template-columns: 1fr 3.5fr;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1600px) {
    .unit-info {
        grid-template-columns: 1fr 6fr;
    }
  }

.course-form-label {
    margin-bottom: -2em;
    padding: 1em 0;
}