/* Add Review & Course Pages */

.add-form {
    display: flex;
    flex-direction: column;
}

#add-course .add-form {
    gap: 1vh;
    padding: 0 3em;
    justify-content: space-evenly;
}

.add-form label, .form-input label  {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-larger);
}

.form-btn {
    display: flex;
    justify-content: flex-start;
}

.form-title {
    font-size: var(--font-size-subheader);
    font-weight: var(--font-weight-medium);
    margin: 1em 0;
}