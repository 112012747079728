/* Home Page */

.intro {
    font-family: var(--font-heading);
}

.intro-banner {
    display: flex;
    align-items: flex-end;
    z-index: 1;
    margin: 0 15vw;
    font-size: 2em;
    color: white;
}

.intro-banner h1 {
    margin-bottom: 0.3em;
    line-height: 1;
    overflow: hidden;
    text-transform: uppercase;
}

.intro-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    min-height: 30vh;
    text-align: center;
    background-color: white;
}

.intro-description h5 {
    color: var(--secondary-color);
    letter-spacing: var(--spacing-small);
}

.intro-description h6 {
    text-transform: uppercase;
    color: var(--color-light);
    line-height: var(--line-height-h4);
    letter-spacing: var(--spacing-medium);
}

#courses {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.courses-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.courses-header h2 {
    font-size: var(--font-size-heading);
    color: var(--color-heading);
}

.courses-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.courses-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    align-items: stretch;
    justify-content: space-between;
}

.courses-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* TODO: Check again when API works */

.courses-reviews {
    margin-top: 10px;
}

.courses-reviews ul {
    list-style-type: none;
    padding-left: 0;
}

.courses-reviews li {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 10px;
}

.courses-reviews-date {
    font-weight: 500;
    color: var(--color-description);
}