#course-details {
    display: flex;
    flex-direction: row;
    margin: 0 15vw;
    min-height: calc(100vh - 60px - 15vh );
}

.course-overview {
    display: flex;
    flex-direction: column;
    background-color: lightgray;
    color: var(--color-heading);
    width: 40vw;
    padding: 3em;
}

.course-overview h1 {
    color: var(--color-label);
    font-size: var(--font-size-subtitle);
}

.course-overview h2 {
    font-weight: var(--font-weight-bolder);
    font-family: var(--font-heading);
}

.course-overview p {
    color: var(--color-text);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    line-height: 1.5;
    margin: 1em 0;
}

.course-summary p {
    color: var(--color-description);
}

.course-reviews {
    display: flex;
    flex-direction: column;
    width: 60vw;
    padding: 3em;
}

.course-rating {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 0;
    font-family: var(--font-heading);
    font-weight: var(--font-weight-bolder);
    color: var(--color-text);
}

.course-rating li {
    width: 14em;
    padding: 1em;
    margin: 1vh;
    box-shadow: var(--box-shadow-light);
    border-radius: 12px;
    text-align: center;
    background-color: white;
}