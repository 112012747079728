/* Color */
:root {
    /* Brighter Red */
    --primary-color: #76232F;

    /* Darker Red */
    --secondary-color: #66272E;

    --button-color: #580013b0;

    --background-color: #f9f9f9;

    /* Font colors */
    --color-heading: #222;
    --color-label: #333;
    --color-text: #444;
    --color-description: #777;
    --color-light: #999;
    --color-placeholder: #bbb;
}


/* Typography */
:root {

    /* Main font for body text */
    --font-body: 'Roboto', sans-serif;

    /* Font for headings */
    --font-heading: 'Montserrat', sans-serif;

    /* Font for titles */
    --font-title: 'Playfair Display', serif;

    /* Font for special text */
    --font-variation: 'Roboto Slab', serif;

    /* Font sizes */
    --font-size-smaller: 16px;
    --font-size-small: 18px;
    --font-size-base: 20px;
    --font-size-larger: 24px;
    --font-size-header: 2em;
    --font-size-subheader: 1.5em;

    --font-size-medium: 1.3vw;
    --font-size-large: 1.5vw;
    --font-size-heading: 1.5em;
    --font-size-label: 1.3em;
    --font-size-title: 3vw;
    --font-size-subtitle: 2.2vw;

    --font-size-h1: 3.4vw;
    --font-size-h2: 2vw;
    --font-size-h3: 1.6vw;
    --font-size-h4: 1.4vw;
    --font-size-h5: 2.5vw;
    --font-size-h6: 1.1vw;

    /* Font weights */
    --font-weight-light: 300;
    --font-weight-medium: 400;
    --font-weight-bold: 500;
    --font-weight-bolder: 600;

    /* Spacing */
    --spacing-small: 0.05em;
    --spacing-medium: 0.2em;
    --spacing-large: 0.4em;

    /* Line height */
    --line-height-h1: 1.6;
    --line-height-h6: 1.5;
}

@media only screen and (min-width: 2300px) {
    :root {
    --font-size-title: 4em;
    --font-size-subtitle: 2.8em;
    --font-size-h1: 2.1em;
    --font-size-h2: 1.8em;
    --font-size-h3: 1.5em;
    --font-size-h4: 1.3em;
    --font-size-h5: 2em;
    --font-size-h6: 1.3em;
    }
  }

  @media only screen and (min-width: 1600px) {
    :root {
        --font-size-medium: 22px;
        --font-size-large: 24px;
    }
  }


/* Borders and shadows */
:root {

    /* Border radius */
    --border-radius-small: 8px;
    --border-radius-medium: 12px;
    --border-radius-large: 30px;

    /* Box shadows */
    --box-shadow-light: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    --box-shadow-dark: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
 }


 /* Container */
 :root {
    /* Navbar height */
    --container-padding-top: 60px;

    --container-margin: 6vh 15vw;
}