/* Review Page */


#add-review {
    position: relative;
}

#review-form {
    margin-top: -1em;
}

#review-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    margin: 1em 0;
}

#review-input label {
    padding: 0;
    line-height: 2;
    display: block;
}

.course-name {
    margin-bottom: -0.6em;
}

.slider {
    width: 100%;
    margin: 0.5em 0;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-input div p {
    font-size: 1em;
    color: #666;
}

.slider input[type="range"] {
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.workload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.workload-hrs {
    margin: 0 0.5em;
    color: var(--color-light);
    line-height: 2;
    font-style: italic;
}

