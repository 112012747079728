/* Login & Register pages */
.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow: hidden;
    font-family: var(--font-body);
}

.auth {
    display: flex;
}

.mq-logo {
    position: fixed;
    bottom: 3em;
    left: 3em;
}

section.left,
section.right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: white;
}

section.left {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 30vw;
    background-color: var(--secondary-color);
}

section.left p {
    font-family: var(--font-variation);
    font-weight: var(--font-weight-light);
    line-height: var(line-height-h6);
    font-size: var(--font-size-h6);
    margin: 1em;
    padding: 1em;
}

section.left h1 {
    overflow-y: hidden;
    font-family: var(--font-title);
    font-size: var(--font-size-title);
    text-align: center;
    letter-spacing: -2px;
    line-height: 120%;
}

section.right {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 70vw;
}

section.right .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/mq.jpg);
    background-position: center;
    background-size: cover;
    filter: brightness(0.4) contrast(1.1);
    z-index: -1;
}

#image2 {
    background-image: url(../../assets/mq-law.jpg);
}

section.right h1 {
    font-size: var(--font-size-subtitle);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: var(--font-weight-light);
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    font-family: var(--font-heading);
    line-height: 1.5em;
}

section.right form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    gap: 0.5em;
}

@media only screen and (max-width: 2300px) {
    section.right form {
        gap: 0.3em;
    }   
    
}

section.right form div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5em 0;
}

section.right form div label {
    margin: 0.5em 0;
}

.submitBtn {
    background: var(--button-color);
    border: 1px solid white;
    border-radius: 30px;
    width: 100%;
    margin: 0.5em 0.5em;
    padding: 0.3em 1.5em;
    color: white;
    font-size: 2em;
}

.submitBtn p {
    font-size: var(--font-size-large);    
}

.submitBtn:hover {
    cursor: pointer;
    filter: brightness(0.85);
}

#googleBtn {
    background: black;
    color: white;
    font-size: var(--font-size-large);
    padding: 0.7em;
}

.sign {
    display: flex;
    flex-direction: row;
}

.sign {
    margin: 1.2em 0 0.5em 0;
}

.sign h2 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-light);
    padding: 0.2em;
}

.sign a h2 {
    font-weight: var(--font-weight-medium);
}

.sign a {
    text-decoration: underline;
}

#sign-info {
    font-style: italic;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-light);
}