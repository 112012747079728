*,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-size: var(--font-size-base);
}

body {
    font-family: var(--font-body);
    color: var(--color-text);
    background-color: var(--background-color);
}

/* Links and buttons */

a {
    text-decoration: none;
    color: inherit;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Layout */

main {
    position: relative;
    /* Gap for Navbar */
    padding-top: var(--container-padding-top);
} 

article.content {
    position: relative;
    margin: var(--container-margin);
}


/* Headings */

h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-bolder);
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-bold);
}

h5 {
    font-family: "Montserrat", sans-serif;
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-medium);
    color: var(--primary-color);
}

h6 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-medium);
}

.error-msg {
    color: red;
    font-size: var(--font-size-small);
}

/* Horizontal rule */

hr {
    border: 1.5em solid transparent;
  }


  /* Form */
  label {
    font-weight: var(--font-weight-label);
    color: var(--color-label);
  }

  textarea {
    resize: none;
  }


  /* Navbar */

.nav-link {
    display: flex;
    align-items: center;
    margin-left: 60px;
    text-transform: capitalize;
    text-align: center;
    font-size: var(--font-size-small);
}

#nav-link-right {
    margin-left: 0;
    margin-right: 30px;
}

@media only screen and (max-width: 1100px) {
    .nav-link {
        margin-left: 30px;
      font-size: var(--font-size-smaller);
    }
  }