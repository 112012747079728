#mypage {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5em;
  margin: 0 15vw;
}

#my-title {
  margin-top: 0;
}

.my-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.my-review {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.info-msg {
  color: gray;
  font-size: var(--font-size-h4);
  font-style: italic;
  line-height: 2em;
}